<template>
  <div class="main-templates">
    <b-row class="match-height">
      <b-col :key="index" v-for="(template, index) in templates" md="6" lg="4">
        <b-link
          class="text-body"
          :to="{ name: 'template-to-run', params: { id: template.id } }"
        >
          <b-card
            :img-src="template.mainImage"
            img-alt="Card image cap"
            img-top
            :title="template.title"
          >
            <b-card-text>
              {{ template.description }}
            </b-card-text>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
            >
              Go Somewhere
            </b-button>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";

import {
  BRow,
  BCol,
  BLink,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
  BCard,
} from "bootstrap-vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    BCard,
  },
  props: ["templates"],
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    };
  },
  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
  },
};
</script>
